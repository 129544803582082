.container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52%;
    background-color: #fff;
    padding: 0.5rem;
    max-height: 96%;
    .modalContent{
        width: 100%;
    }
}