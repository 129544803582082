.tableContainer {
  display: flex;
  // grid-template-columns: 1fr 0.6fr;
  .bomViewTableSec {
    height: 86vh;
    width: 100%;
    // display: flex;
    .tableHCell {
      padding: 0.3rem 0.5rem;
      // color: white;
      text-align: center;
    }
    .tableSNCell {
      width: 5rem;
      padding: 0.3rem 0.5rem;
    }
    .tableCell {
      padding: 0.3rem 0.5rem;
      width: 33%;
      border-color: #007fff14 !important;
    }
  }
  .BomImage {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 25rem;
    min-height: 20rem;
    padding: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
    .bomImageCard {
      height: 115px;
      max-height: 25rem;
    }
    .bomImageCardImage {
      height: 16.9rem;
    }
    .bomImageCardDoc {
      height: 115px;
    }
    .bomImageCard:hover {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
  }
  .BomImageNoImage {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 20rem;
    padding: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
    .bomImageCard {
      height: 115px;
      max-height: 25rem;
    }
    .bomImageCardImage {
      height: 140px;
      margin: 0 auto;
      max-width: 200px;
    }
    .bomImageCardDoc {
      height: 115px;
    }
    .bomImageCard:hover {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
  }
  .BomImagethreeSixty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 20rem;
    max-height: 25rem;
    padding: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
  }
  .BomImagethreeSixty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 20rem;
    max-height: 25rem;
    padding: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
    .bomImageCardImage {
      height: 160px;
      max-height: 25rem;
    }
    .bomImageCardDoc {
      height: 160px;
    }
    .bomImageCard:hover {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
  }
  .BomImagethreeSixty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 20rem;
    max-height: 35rem;
    padding: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
    .bomImageCard {
      height: 160px;
      max-height: 25rem;
    }
    .bomImageCardImage {
      height: 16.9rem;
    }
    .bomImageCardDoc {
      height: 160px;
    }
    .bomImageCard:hover {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
  }
  .bomgridContainer {
    width: 100%;
    height: 10rem;
    max-height: 10rem;
    border-radius: 10px;
    // object-fit: contain;
    .bomDivNoImage{
      height: inherit;
      width: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.2rem solid #ddefff;
      border-radius: 10px;
      font-weight: 500;
    }
    .bomgridImages {
      cursor: pointer;
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
    .imageOptions {
      display: none;
      margin-top: -3rem;
     
      .optionDiv {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        align-items: center;
        column-gap: 1rem;
        justify-content: center;
        background-color: rgb(221 239 255 / 69%);
      }
    }
    .bomUploadVideo {
      cursor: pointer;
      height: 10rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      border-radius: 10px;
      padding: 0 1rem;
    }
  }

  .bomgridContainer:hover .imageOptions {
    display: block;
    position: relative;
    .optionDiv {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
      align-items: center;
      column-gap: 1rem;
      justify-content: center;
      background-color: rgb(221 239 255 / 69%);
      
    }
  }
  .NoDocmentThreeSixty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 20rem;
    max-height: 30rem;
    padding: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
    .bomImageCard {
      height: 160px;
      max-height: 25rem;
    }
    .bomImageCardDoc {
      height: 160px;
    }
    .bomImageCard:hover {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
  }
  .BomImageNoDoc {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-height: 42vh;
    min-height: 20rem;
    padding: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
    .bomImageCard {
      height: 116px;
      max-height: 25rem;
    }
    .bomImageCardImage {
      height: 16.9rem;
    }
    .bomImageCardDoc {
      height: 160px;
    }
    .bomImageCard:hover {
      transform: scale(1.05);
      transition: transform 0.5s ease-in-out;
    }
  }

  .documentSec {
    .tableCell {
      padding: 0.3rem 0.5rem;
      // width: 33%;
      border-color: #007fff14 !important;
    }
  }
  .customHaeder{
    width:40rem !important;
    color:red;
  }  
}

.Dataheader {
  background-color: #ddefff;
  color: #007fff;
  height: 3rem;
}
.tableContainerS {
  overflow-y: hidden;
  padding: 1rem;
  margin-bottom: 1rem;
  .tablemultiCell {
    white-space: nowrap;
    padding: 0.3rem 0.5rem;
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
  .tablemultiCellData {
    padding: 0.3rem 0.5rem;
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
  .tablemultiCell:first-child,
  .tablemultiCellData:first-child {
    border-left: none;
  }
}
.tableContainerS::-webkit-scrollbar-track {
  // border: 1px solid black;
  background-color: #f5f5f5;
  border-radius: 2px;
}
.tableContainerS::-webkit-scrollbar {
  // width: 5px;
  height: 5px;
  // background-color: #18aedb;
  border-radius: 2px;
}
.tableContainerS::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#09c6f9, #045de9);
  border-radius: 2px;
}

.projImage {
  margin: 5px 0.5rem 10px;
  border-radius: 10px;
  padding: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.5s ease;
  .proimg {
    background-color: white;
    width: 160px;
    height: 140px;
    object-fit: contain;
  }
}
.projImage:hover {
  overflow: hidden;
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .tableContainer {
    display: flex;
    width: 100%;
    // grid-template-columns: 1fr 0.6fr;
    .bomViewTableSec {
      height: 82vh;
      .tableHCell {
        padding: 0.3rem 0.5rem;
        // color: white;
        text-align: center;
      }
      .tableCell {
        padding: 0.3rem 0.5rem;
      }
    }
    .bomViewImageSec {
      height: 82vh;
      .BomImage {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 25rem;
        padding: 1rem;
        row-gap: 1rem;
        column-gap: 1rem;
      }
    }
    .BomImageNoImage {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      min-height: 20rem;
      padding: 1rem;
      row-gap: 1rem;
      column-gap: 1rem;
      .bomImageCard {
        height: 115px;
        max-height: 25rem;
      }
      .bomImageCardImage {
        height: 100px;
        margin: 0 auto;
        max-width: 134px;
      }
      .bomImageCardDoc {
        height: 115px;
      }
      .bomImageCard:hover {
        transform: scale(1.05);
        transition: transform 0.5s ease-in-out;
      }
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .tableContainer {
    display: flex;
    width: 100%;
    // grid-template-columns: 1fr 0.8fr;
    .bomViewTableSec {
      height: 88vh;
      .tableHCell {
        padding: 0.3rem 0.5rem;
        // color: white;
        text-align: center;
      }
      .tableCell {
        padding: 0.3rem 0.5rem;
      }
    }
    .bomViewImageSec {
      height: 83vh;
      width: 50%;
      display: flex;
      transition-property: width;
      transition-duration: 0.6s;
      transition-timing-function: ease-in;
      .expandIconBox {
        // float: right;
        width: 2.5rem;
        border-left: 1px solid #007fff14;
        .iconBtn {
          position: relative;
          margin-left: -1.3rem;
          z-index: 1000;
        }
        .iconBtnTag {
          position: relative;
          margin-top: 2.5rem;
          margin-left: -1.3rem;
          z-index: 1000;
        }
      }
      .BomImage {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 25rem;
        padding: 1rem;
        row-gap: 1rem;
        column-gap: 1rem;
        .bomImageCard:hover {
          transform: scale(1.05);
          transition: transform 0.5s ease-in-out;
        }
      }
      .documentSec {
        .tableCell {
          padding: 0.3rem 0.5rem;
          // width: 33%;
          border-color: #007fff14 !important;
        }
      }
    }
    .bomViewImageSec_close {
      height: 83vh;
      width: 2.5rem !important;
      transition-property: width;
      transition-duration: 0.6s;
      transition-timing-function: ease-out;
      display: flex;
      overflow: hidden;
      // border-left: 1px solid #007fff14;
      .expandIconBox {
        // float: right;
        width: 2.5rem;
        border-left: 1px solid #007fff14;
        .iconBtn {
          position: relative;
          margin-left: -0.2rem;
          z-index: 1000;
        }
        .iconBtnTag {
          position: relative;
          margin-top: 2.5rem;
          margin-left: -1.2rem;
          z-index: 1000;
        }
      }
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .tableContainer {
    display: flex;
    width: 100%;
    // grid-template-columns: 1fr 0.8fr;
    .bomViewTableSec {
      height: 88vh;
      .tableHCell {
        padding: 0.3rem 0.5rem;
        // color: white;
        text-align: center;
      }
      .tableCell {
        padding: 0.3rem 0.5rem;
      }
    }
    .bomViewImageSec {
      height: 88vh;
      width: 50%;
      display: flex;
      transition-property: width;
      transition-duration: 0.6s;
      transition-timing-function: ease-in;
      overflow: visible;
      .expandIconBox {
        // float: right;
        width: 2.5rem;
        border-left: 1px solid #007fff14;
        .iconBtn {
          position: relative;
          margin-left: -1.3rem;
          z-index: 1000;
        }
        .iconBtnTag {
          position: relative;
          margin-top: 0.5rem;
          margin-left: -1.3rem;
          z-index: 1000;
        }
      }
      .BomImage {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 32rem;
        padding: 1rem;
        row-gap: 1rem;
        column-gap: 1rem;
        .bomImageCard:hover {
          transform: scale(1.05);
          transition: transform 0.5s ease-in-out;
        }
      }
      .documentSec {
        .tableCell {
          padding: 0.3rem 0.5rem;
          // width: 33%;
          border-color: #007fff14 !important;
        }
      }
    }
    .bomViewImageSec_close {
      height: 88vh;
      width: 2.5rem !important;
      transition-property: width;
      transition-duration: 0.6s;
      transition-timing-function: ease-out;
      display: flex;
      overflow: hidden;
      // border-left: 1px solid #007fff14;
      .expandIconBox {
        // float: right;
        width: 2.5rem;
        border-left: 1px solid #007fff14;
        .iconBtn {
          position: relative;
          margin-left: -0.2rem;
          z-index: 1000;
        }
        .iconBtnTag {
          position: relative;
          margin-top: 0.5rem;
          margin-left: -1.2rem;
          z-index: 1000;
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
}
