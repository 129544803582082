.treeViewData {
    padding: 0.2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
  
    .treeViewText {
      line-height: 1.2 !important;
      font-size: 12px;
      display: flex;
      align-items: center;
      column-gap: 0.3rem;
    }
  }


  .treeViewDataACC {
    padding: 0rem;    
    .treeViewText {
        line-height: 1.2 !important;
        text-transform: capitalize;
        font-size: 1rem;
    }
}