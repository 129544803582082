.materialName {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom-color: #ddefff !important;
  width: 100% !important;
}

.qty {
  border-bottom-color: #ddefff !important;
}

.unitCost {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom-color: #ddefff !important;
  width: 100% !important;
}

.totalCost {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom-color: #ddefff !important;
  width: 100% !important;
}
.aliasName {
  width: 15rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.costEstAccordian {
  height: 51vh;
}

.costEstAccordianInactive {
  height: 73vh;
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .costEstAccordian {
    height: 33vh;
  }
  .costEstAccordianInactive {
    height: 63vh;
  }
  .aliasName {
    width: 14rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
