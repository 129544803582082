.expandIconBox {
    width: 4rem;
    padding-top: 0.4rem;
    text-align: center;
    border-right: 1px solid #007fff14;
    height: 89vh;
  
    .sideTitle {
      transform: rotate(270deg);
      white-space: nowrap;
      margin-top: 40vh;
    }
  }
  
  .drawBtn:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
  
  // .Data_card {
  //   background: white;
  //   display: flex;
  //   height: 5rem;
  //   margin: 0.2rem 1rem 1rem;
  //   border-radius: 0.5rem;
  //   border-radius: 10px;
  //   box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  // }
  
  .box_container {
    display: flex;
    justify-content: center;
    flex-direction: row;   
    font-size: 1rem;
    cursor: pointer;
    width: 100%;  
    margin-left: 0.1rem;
    padding: 0.2rem 0.5rem 0.2rem 0;   
    border-radius: 3px;
    text-transform: capitalize;
  
  }
  
  .active_box_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row; 
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    padding: 0.2rem 0.5rem 0.2rem 0;  
    border-radius: 3px;
    text-transform: capitalize;
    margin-left: 0.1rem;
    color: white;
    background-color: #007fff;
  }

  @media all and (min-width: 1200px) and (max-width:1535px){
    .expandIconBox {
      width: 4rem;
      padding-top: 0.4rem;
      text-align: center;
      border-right: 1px solid #007fff14;
      height: 87vh;
    
      .sideTitle {
        transform: rotate(270deg);
        white-space: nowrap;
        margin-top: 35vh;
      }
    }
  }