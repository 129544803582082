.inputContainer-verification {
  margin-bottom: 2rem;
  position: relative;
}

// .ui.form .inline.field > label {
//   font-size: 1.1rem !important;
//   font-weight: lighter !important;
//   margin-top: 10rem;
// }
// .ui.form .field .ui.input input,
// .ui.form .fields .field .ui.input input {
//   width: 15.5rem !important;
// }

// .ui.form .field > label {
//   font-size: 1.1rem !important;
//   font-weight: lighter !important;
// }

.signup-form--buttons {
  font-size: 1.1rem !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  color: #007fff !important;
  /* padding: 0.78571429em 1.5em; */
  // margin-left: -4%;
  margin-bottom: 3rem;
  cursor: pointer;
  /* margin: 0 12rem 0 0; */
  // transition-duration: 0.4s;
}
.signup-form-text {
  font-size: 2rem !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  color: #007fff !important;
  //border: solid 2px #007fff !important;
  padding: 0.78571429em 1.5em;
  margin-bottom: 1rem;
}

.form-container {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  justify-content: center;
  align-content: center;
  padding-bottom: 10rem;
  padding-left: 5%;
}

.image_group {
  display: grid;
  justify-content: center;
  align-content: center;
}
.card-root {
  border-radius: 100%;
  width: 13rem;
  height: 13rem;
  cursor: pointer;
  object-fit: cover;
  position: relative;
  &:hover {
    box-shadow: 0px 20px 40px -15px rgba(0, 0, 0, 0.5);
    transform: translateY(-3px);
    transition: 0.15s all;
  }
}

.form-group {
  display: grid;
  justify-content: center;
}

// .ui input {
//     margin-top: -5% !important;
// }
// .ui.form .field:last-child,
// .ui.form .fields:last-child .field {
//   margin-bottom: 0;
//   // display: flex !important;
//   flex-direction: row !important;
//   align-items: flex-end !important;
// }

.Sign_in_Container {
  animation: fadein ease 1s;
  animation-fill-mode: forwards;
  margin-top: -7%;
}
.user-image {
  display: flex;
  justify-content: center;
  width: 25rem;
  height: 25rem;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.signup-form--button {
  font-size: 1.1rem !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  color: #007fff !important;
  border: solid 2px #007fff !important;
  padding: 0.78571429em 1.5em;
  cursor: pointer;
  margin-left: 0.25em;
  transition-duration: 0.4s;
  &:hover {
    background-color: #007fff !important;
    color: #fff !important;
  }
}

@media all and (min-width: 1536px) {
  .phoneInput {
    width: 100%;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  .phoneInput {
    width: 100%;
  }
  .card-root {
    border-radius: 100%;
    width: 11rem;
    height: 11rem;
    cursor: pointer;
    object-fit: cover;
    &:hover {
      box-shadow: 0px 20px 40px -15px rgba(0, 0, 0, 0.5);
      transform: translateY(-3px);
      transition: 0.15s all;
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .phoneInput {
    margin-left: 0rem;
  }
}
@media all and (max-width: 600px) {
  .phoneInput {
    margin-left: 0rem;
    text-align: left;
  }
}
