// .child {
//     margin: 0.5rem auto;
//     width: 80%;
//     position: absolute;
//     height: 75%;
//     top: 10%;
//     left: 10%;
//     background-color: #fff;
//     border-radius: 5px;
//     .scannerClose{
//         position: relative;
//         float: right;
//         cursor: pointer;
//         margin: 0.5rem;
//     }
//     .scannerSection{
//         width: 80%;
//         margin: 2rem auto;
//     }
//     &:focus-visible{
//         outline: none;
//     }
// }

.child {
    margin: 0.5rem auto;
    width: 50%;
    position: absolute;
    height: auto;
    top: 10%;
    left: 25%;
    background-color: #fff;
    border-radius: 5px;
    .scannerClose{
        position: relative;
        float: right;
        cursor: pointer;
        margin: 0.5rem;
    }
    .scannerSection{
        width: 95%;
        // margin: 2rem auto;
       
    margin: -12rem auto;
    // height: 32rem;
    }
    &:focus-visible{
        outline: none;
    }
}
.inputbox{
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
 
}

.searchInput {
    color: #007fff;
    background-color: transparent;
    width: 27rem;
    height: 2rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid !important;
    border-bottom: none;
    border-top: none;
    border-right: none;
    border-left: none;
}

.search {
    text-align: center;
    // width: 55%;
    margin: 0 auto;
}

.searchForm {
    display: inline-block;
    margin-top: 0.5rem;
    border-bottom: 2px solid;
}

.searchIcon {
    color: #007fff;
    width: 3rem;
    height: 1.5rem;
    margin-right: -2.5rem;
    cursor: pointer;
    z-index: 1;
    position: relative;
    margin-bottom: -1.5rem;
}

.AutoSearchBox {
    height: auto;
    max-height: 15rem;
    overflow: auto;
    width: 27rem;
    margin-top: 0.1rem;
    background-color: aliceblue;
    position: absolute;
    z-index: 11;
    border-radius: 2px;
    border-bottom: 2px solid #007fff14;
    border-right: 2px solid #007fff14;
    border-left: 2px solid #007fff14;

    .searchSubText {
        color: #007fff;
        cursor: pointer;
        text-align: left;
    }
}

.textarea:focus,
input:focus {
    outline: none;
}
.innserSearchSubText {
    display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid #007fff14;
    padding: 0 0.6rem;
    background-color: white;
    transition: transform 0.5s ease;
    align-items: center;
    font-size: 0.9rem;
    justify-content: space-between;
  }

  .qrScannerdiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 84vh;
    flex-direction: row;
    .qrScannerleft {
      display: flex;
      flex-direction: column;
      align-items: center;
      .qrScannerimg {
        width: 20rem;
        margin-bottom: 1rem;
        cursor: pointer;
      }
      .titletext {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
      }
    }
    .qrScannerright {
      text-align: center;
      .titletext {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  .videoStyle {
    margin-top: -6rem;
    margin-bottom: -5rem;
  }

  @media all and (min-width: 1200px) and (max-width:1535px){
    .searchInput {
        color: #007fff;
        background-color: transparent;
        width: 24rem;
        height: 2rem;
        padding-left: 1rem;
        padding-bottom: 0.5rem;
        border-bottom: none;
        border-top: none;
        border-right: none;
        border-left: none;
    }
    .qrScannerdiv {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 84vh;
        flex-direction: row;
        .qrScannerleft {
          display: flex;
          flex-direction: column;
          align-items: center;
          .qrScannerimg {
            width: 20rem;
            margin-bottom: 1rem;
            cursor: pointer;
          }
          .titletext {
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
          }
        }
        .qrScannerright {
          text-align: center;
          .titletext {
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      .videoStyle {
        margin-top: -5rem;
        margin-bottom: -4rem;
      }
  }
  
  
  @media all and (min-width: 900px) and (max-width:1199px) {
  
  }
  
  @media all and (min-width: 601px) and (max-width: 899px) {
  
  }
  
  
  @media all and (max-width: 600px) {
    .child {
    margin: 0.5rem auto;
    width: 80%;
    position: absolute;
    height: auto;
    top: 10%;
    left: 10%;
    background-color: #fff;
    border-radius: 5px;
  //   .scannerClose{
  //     position: relative;
  //     float: right;
  //     cursor: pointer;
  //     margin: 0.5rem;
  // }
  .scannerSection{
    width: 95%;
    margin: 0 auto;
  }
  &:focus-visible{
      outline: none;
  }
    }

    .searchInput {
        color: #007fff;
        background-color: transparent;
        width: 100%;
        height: 2rem;
        padding-left: 1rem;
        padding-bottom: 0.5rem;
        border-bottom: none;
        border-top: none;
        border-right: none;
        border-left: none;
    }
    .qrScannerdiv {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 75vh;
        flex-direction: column;
        .qrScannerleft {
          display: flex;
          flex-direction: column;
          align-items: center;
          .qrScannerimg {
            width: 15rem;
            margin-bottom: 1rem;
            cursor: pointer;
          }
          .titletext {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
          }
        }
        .qrScannerright {
          text-align: center;
          .titletext {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      .videoStyle {
        margin-top: 0rem;
        margin-bottom: 0rem;
      }
  }