body {
  margin: 0;
  font-family: "Roboto", sans-serif, Helvetica, Arial !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  font-size: 1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media all and (min-width: 1921px) {
  html {
    font-size: 100%;
  }
}

@media all and (min-width: 1536px) and (max-width: 1920px) {
  html {
    font-size: 87.5%;
  }
}

@media all and (min-width: 1200px) and (max-width: 1535px) {
  html {
    font-size: 75%;
  }
}
@media all and (min-width: 900px) and (max-width: 1199px) {
  html {
    font-size: 75%;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  html {
    font-size: 75%;
  }
}
/* @media all and (min-width: 1px) and (max-width: 600px) {
  html {
    font-size: 75%;
  }
} */

/* @media all and (min-width: 3500px) {
  html {
    font-size: 200%;
  }
} */
/* 
@media all and (min-width: 1200px) and (max-width: 1535px) {
  html {
    font-size: 75%;
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  html {
    font-size: 75%;
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  html {
    font-size: 75%;
  }
} */
/* 
@media all and (max-width: 600px) {
} */
html {
  scroll-behavior: smooth;
}





