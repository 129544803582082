.expandIconBox {
  width: 4rem;
  padding-top: 0.4rem;
  text-align: center;
  border-right: 1px solid #007fff14;
  height: 89vh;

  .sideTitle {
    transform: rotate(270deg);
    white-space: nowrap;
    margin-top: 40vh;
  }
}

.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}


.drawBtn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}



.box_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.1rem;
  padding: 0.3rem 0.5rem;
  padding-left: 0;
  font-size: 1rem;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  text-transform: capitalize;
}

.active_box_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.1rem;
  padding: 0.3rem 0.5rem;
  padding-left: 0;
  font-size: 1rem;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  text-transform: capitalize;
  color: white;
  background-color: #007fff;

}