// $track-color: #e1e1f3;
// $thumb-color: #7e8a96;
// $thumb-hover-color: #555;
// background: $thumb-color;
$palette-primary-light: #ddefff;
$palette-primary-main: #007fff;
$palette-primary-dark: #0069d3;

Body {
  overflow: hidden;
  background: transparent;
}

.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault {
  background-color: aliceblue;
  font-size: 1rem;
  text-transform: uppercase;
}

.MuiIconButton-root:hover {
  background-color: aliceblue !important;
  color: $palette-primary-main;
}




//Pagination
.MuiButtonBase-root.MuiPaginationItem-root .MuiPaginationItem-text {
  color: $palette-primary-main !important;
}

.MuiPaginationItem-root:hover,
.MuiPaginationItem-text:hover {
  transform: Scale(1.1) !important;
  transition: transform 0.5s ease !important;
  background-color: $palette-primary-light !important;
}

.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  color: white !important;
  background-color: $palette-primary-main !important;
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.SearchComponent_accordContainer__v1Ly9.css-1fjvggn-MuiPaper-root-MuiAccordion-root {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid;
}

.react-tel-input .form-control {
  width: 100%;
  color: $palette-primary-main;
  border-color: $palette-primary-light;
  font-size: 1rem;
}

.react-tel-input .flag-dropdown {
  border-color: $palette-primary-light;
}

.MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: aliceblue;
}

.MuiPaper-root-MuiAccordion-root {
  border: 1px solid $palette-primary-main;
}

// Accordian Css
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root {
  border-color: #bbdffb;
  color: $palette-primary-main;
  border-right: none;
  border-left: none;
}

.MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0 0px;
}

.MuiAccordionSummary-content {
  margin: 0;
}

.MuiAccordionSummary-content.Mui-expanded.css-1betqn-MuiAccordionSummary-content {
  margin: 0 0 0 8px;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
}

.MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin-left: 0;
}

// Data Grid
.MuiDataGrid-root {
  border-color: white !important;
  box-shadow: none !important;
  border: none !important;
}

.MuiDataGrid-columnHeaderTitle {
  color: $palette-primary-main;
}

.MuiDataGrid-root .MuiDataGrid-row {
  color: $palette-primary-main;
}

.MuiDataGrid-root .MuiDataGrid-cell .MuiTableCell-root {
  border-color: $palette-primary-light !important;
}

.MuiDataGrid-columnHeaders {
  background-color: $palette-primary-light;
}

.css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  background-color: aliceblue;
}

.MuiInputBase-root.MuiInput-root.MuiSelect-root:before {
  border-bottom-color: $palette-primary-light !important;
}

// swal button color change ----------
.swal-title,
.swal-text,
.swal2-title,
.swal2.text,
div:where(.swal2-container) div:where(.swal2-popup) {
  color: $palette-primary-main;
  text-align: center;
}

.swal-button--permanent,
.swal-button--confirm {
  background-color: $palette-primary-main;
}

.swal-button--confirm:not([disabled]):hover {
  background-color: $palette-primary-dark;
}

.swal2Container {
  z-index: 9999; /* Adjust the z-index value as needed */
}


div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  background-color: $palette-primary-main !important ;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus{
  box-shadow: 0 0 0 3px rgba(0, 127, 255, 0.5) !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel{
  background-color: #b9b9b9;
}

textarea:focus-visible {
  outline: 2px solid $palette-primary-light !important;
  border-radius: 3px;
  color: $palette-primary-main !important;
}
textarea::placeholder{
  color: #007fff;
}
::placeholder{
  color: $palette-primary-light;
}
.MuiInputBase-root .MuiFilledInput-root .Mui-disabled {
  background-color: $palette-primary-light !important;
}

//Captcha
.hLCrav .rnc {
  margin: 0 auto;
  background-color: #007fff14 !important;
  max-width: 100% !important;
}

.hLCrav .rnc-input {
  color: $palette-primary-main !important;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
}

*:focus-visible {
  border: none;
  outline: none;
}

.MuiInputBase-root-MuiInput-root-MuiSelect-root:before::before {
  border-color: #007fff;
}

.dx-header-row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
}

.dx-datagrid-table-fixed {
  width: 100% !important;
  border: 1px solid $palette-primary-light !important;
}

.dx-button-text {
  cursor: pointer !important;
}

.dx-button-content {
  float: right !important;
  margin-bottom: 0.5rem !important;
  margin-right: 1rem !important;
}

.MediaExcelModalDownload_excelpopBox__288ik>div:first-child {
  width: 100% !important;
}

.slick-prev:before {
  content: '←';
  color: #007fff;
}
.slick-next:before {
  content: '→';
  color: #007fff;
}
ul.slick-dots {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-c0g5gl-MuiDataGrid-root .MuiDataGrid-cell:focus{
  outline: none !important;
}


/* Menu Items Costing Configurations */
// .my-menu {
//   border: 2px solid green;
// }

.my-menuitem {
  color: #007fff;
  background-color: #FFF;
}

.my-menuitem-hover {
  color: #007fff;
  background-color: aliceblue;
}
.jodit-status-bar{
  display: none;
}

.tooltip {
  visibility: hidden;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 90%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  will-change: opacity, visibility;
}

.fixed {
  position: fixed;
}

.arrow {
  position: absolute;
  background: inherit;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.noArrow {
  display: none;
}

.clickable {
  pointer-events: auto;
}

.show {
  visibility: visible;
  opacity: var(--rt-opacity);
}

/** Types variant **/
.dark {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.light {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.success {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.warning {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.error {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.info {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}