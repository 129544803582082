.gridcontainer {
    // display: grid;
    display: flex;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 1rem;
    height: 40%; /* Optional: full viewport height */
    width:96% /* Optional: full viewport height */
}

.section {
    display: flex;
    justify-content: center;  
    gap: 1rem;  
    // align-items: center;
    width: 100%;
    min-height: 25rem; /* Optional: equally distribute sections */
}

.box {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
            transform-style: preserve-3d;
            position: relative;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    // display: flex;
    // justify-content: center;
    // align-items: center;
}
.section1 { 
    background-color: ghostwhite;
 }
.section2 { background-color: azure; }
.section3 { background-color: aliceblue; }

$form-header-background: #007fff;
$form-button-background: #007fff;
$form-input-border: #e0e0e0;

.formcontainer {
    max-width: 100%;
    margin: auto auto;
    padding: 13.5px;
    // background: white;
    // border-radius: 10px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    .formheader {
        background-color: $form-header-background;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        padding: 4.5px;
        color: white;

        h2 {
            margin: 0;
        }
    }

    .formbody {
        padding: 20px;

        h3 {
            margin-bottom: 20px;
            color: #333;
        }

        .formgroup {
            margin-bottom: 15px;

            input {
                width: 100%;
                padding: 10px;
                border: 1px solid $form-input-border;
                border-radius: 5px;
                font-size: 16px;
            }
        }

        .formfooter {
            display: flex;
            // justify-content: ;
            // align-items: center;
            float: right;
            margin-top: 10px;

            a {
                color: #4285f4;
                text-decoration: none;
            }

            .loginbutton {
                background-color: $form-button-background;
                color: white;
                padding: 10px 20px;
                border: none;
               
                border-radius: 5px;
                cursor: pointer;
                font-size: 16px;
                transition: background-color 0.3s;

                &:hover {
                    background-color: darken($form-button-background, 10%);
                }
            }
        }
    }
}
