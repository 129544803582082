.counter {
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .digit_1 {
    width: 134px;
    height: 134px;
    border: 2px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    background-color: #4dd9bf;
    align-items: center;
    color: white;
    font-size: 6rem;
    font-weight: 700;
    border-radius: 1rem;
}
.digit_2 {
    width: 134px;
    height: 134px;
    border: 2px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    background-color: #0f61ad;
    align-items: center;
    color: white;
    font-size: 6rem;
    font-weight: 700;
    border-radius: 1rem;
}
.digit_3 {
    width: 134px;
    height: 134px;
    border: 2px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    background-color: #94b856;
    align-items: center;
    color: white;
    font-size: 6rem;
    font-weight: 700;
    border-radius: 1rem;
}
.digit_4 {
    width: 134px;
    height: 134px;
    border: 2px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    background-color: #32ac95;
    align-items: center;
    color: white;
    font-size: 6rem;
    font-weight: 700;
    border-radius: 1rem;
}
  
  
  .digit__inner {
    display: inline-block;
    animation: numberChange 1s ease-in-out infinite;
  }
  
  @keyframes numberChange {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .digit__inner-0,
  .digit__inner-1,
  .digit__inner-2,
  .digit__inner-3,
  .digit__inner-4,
  .digit__inner-5,
  .digit__inner-6,
  .digit__inner-7,
  .digit__inner-8,
  .digit__inner-9 {
    /* Customize digit colors here (optional) */
  }