.projectMainDiv {
  height: 100vh;
  background-size: cover;
  min-height: 100%;
  // min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  // transform:scaleX(-1);
  // background-position: 74.5% 75%;
  // opacity: 0.3;
  // object-fit: cover;
  .landingPage {
    height: 92.5vh;
  }
}

.titleSec {
  align-items: center;
}

.internalIcons:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.projImage {
  margin: 0px 0.9rem;
  // margin-bottom: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 0.5rem;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.5s ease;
  min-width: 250px;
  max-width: 250px;
  min-height: 245px;

  .productTitle {
    text-align: center;
    width: 100%;
    // margin: 0 auto;
    // margin-bottom: 5px;
    font-size: 1rem;
  }
}

.projImage:hover {
  // overflow: hidden;
  transform: scale(1.05);
}

.proimg {
  // background-color: white;
  width: 100%;
  min-width: 100%;
  margin: 0 auto;
  height: 200px;
  min-height: 125px;
  object-fit: contain;
}

.project_name {
  line-height: 1 !important;
  padding: 0.3rem;
  font-size: 1rem !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
}

.iconLink:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

// .project_description {
//   font-size: 14px;
// }
.projectTitlesec {
  // align-items: center;
  // background: aliceblue;
  margin-top: 0rem !important;
  // margin-bottom: 1rem !important;
  padding: 0 1rem;
  margin-left: -1.5rem !important;

  .projectDetails {
    // align-items: center;
    padding-left: 1.5rem !important;
    color: #007fff;

    // padding-bottom: 1rem;
    .innerSection {

      // padding-bottom: 1rem;
      .btnLink {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        .buttonBox {
          .btn {
            padding: 0.4rem 1rem;
            background-color: #007fff;
            font-weight: 400;
            // font-size: 1.3rem;
            width: 71%;
            border-radius: 3rem;
            transition: transform 0.5s ease;
          }

          .btn:hover {
            background-color: #0069d3;
            color: #fff;
            text-decoration: none;
            overflow: hidden;
            transform: scale(1.01);
          }
        }

        .buttonBox:hover {
          transform: scale(1.06);
          transition: transform 0.5s ease;
        }
      }
    }
  }

  .lineGraph {
    height: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .moduleDetails {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 0.2rem;
    row-gap: 10px;

    .project_module {
      line-height: 1.2;
      font-size: 1rem;
    }

    .iconLink:hover {
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
  }

  .carouselDiv {
    width: 71vw;

    // padding-bottom: 1.5rem;
    .projectImagesCoursel {
      // display: flex;
      // flex-wrap: wrap;
      row-gap: 1rem;

      //  height: 125px;
      .projImage {
        margin: 0px 0.9rem;
        // margin-bottom: 0.5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 0.5rem;
        cursor: pointer;
        overflow: hidden;
        transition: transform 0.5s ease;
        min-width: 250px;
        max-width: 250px;
        min-height: 225px;

        .productTitle {
          text-align: center;
          width: 100%;
          // margin: 0 auto;
          // margin-bottom: 5px;
          font-size: 1rem;
        }
      }

      .projImage:hover {
        // overflow: hidden;
        transform: scale(1.05);
      }

      .proimg {
        // background-color: white;
        width: 100%;
        min-width: 100%;
        margin: 0 auto;
        height: 200px;
        min-height: 125px;
        object-fit: contain;
      }

      .progresbar {
        display: flex;
        align-items: center;
        padding: 0.2rem 0rem;

        .progText {
          font-size: 1rem;
        }
      }
    }

    .projectImages {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      row-gap: 1rem;

      //  height: 125px;
      .projImage {
        margin: 0px 0.9rem;
        // margin-bottom: 0.5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 0.5rem;
        cursor: pointer;
        overflow: hidden;
        transition: transform 0.5s ease;
        min-width: 250px;
        max-width: 250px;
        min-height: 225px;

        .productTitle {
          text-align: center;
          width: 100%;
          // margin: 0 auto;
          // margin-bottom: 5px;
          font-size: 1rem;
        }
      }

      .projImage:hover {
        // overflow: hidden;
        transform: scale(1.05);
      }

      .proimg {
        // background-color: white;
        width: 100%;
        min-width: 100%;
        margin: 0 auto;
        height: 200px;
        min-height: 125px;
        object-fit: contain;
      }

      .progresbar {
        display: flex;
        align-items: center;
        padding: 0.2rem 0rem;

        .progText {
          font-size: 1rem;
        }
      }
    }
  }
}

.switchUI {
  color: #007fff;
  position: absolute;
  bottom: 0;
  background: white;
  padding: 10px 16px;
  width: 100%;
  z-index: 1;

  .switchText {
    cursor: pointer;
  }
}


@media all and (min-width: 1200px) and (max-width: 1535px) {
  .projectMainDiv {
    height: 100vh;
    // background-image: url(../../Assets/images/background.png);
    // background-image: url(../../Assets/images/wallpaper3-1.png);
    // background-position: 65% 70%;
    .landingPage {
      height: 92vh;
    }
  }

  .titleSec {
    align-items: center;
  }

  .project_name {
    line-height: 1;
    font-weight: 600 !important;
    // padding-bottom: 0.1rem;
    // font-size: 1.2rem;
    padding-left: 0.2rem;
  }

  // .project_description {
  //   // font-size: 14px;
  // }
  .projectTitlesec {
    // align-items: center;
    // background: aliceblue;
    margin-top: 0rem !important;
    // margin-bottom: 1rem !important;
    padding: 0 1rem;
    margin-left: -2.3rem !important;

    .projectDetails {
      // align-items: center;
      padding-left: 2.5rem !important;
      color: #007fff;
      // padding-bottom: 1rem;

      .innerSection {

        // padding-bottom: 1rem;
        .btnLink {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;

          .buttonBox {
            .btn {
              padding: 0.4rem;
              background-color: #007fff;
              width: 71%;
              font-weight: 400;
              font-size: 1rem;
              transition: transform 0.5s ease;
            }

            .btn:hover {
              background-color: #0069d3;
              color: #fff;
              text-decoration: none;
              overflow: hidden;
              transform: scale(1.01);
            }
          }

          .buttonBox:hover {
            transform: scale(1.06);
            transition: transform 0.5s ease;
          }
        }
      }
    }

    .lineGraph {
      height: 2rem;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .moduleDetails {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 0.2rem;
      row-gap: 3px;

      .project_module {
        line-height: 1.2;
        font-size: 1rem;
      }

      .iconLink:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .carouselDiv {
      width: 71vw;

      // padding-bottom: 1.5rem;
      .projectImagesCoursel {
        // display: flex;
        // flex-wrap: wrap;
        row-gap: 1rem;

        //  height: 125px;
        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 175px;
          max-width: 175px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }

      .projectImages {
        // display: flex;
        // flex-wrap: wrap;
        // row-gap: 1rem;
        //  height: 125px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 1rem;

        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 175px;
          max-width: 175px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .buttonSec {
    margin-top: -1rem !important;

    .innerSection {
      padding-bottom: 0rem;

      .btnLink {
        display: block;

        .btn {
          padding: 0.28rem 1rem;
          background-color: #007fff;
          width: 10rem;
          font-weight: 400;
          justify-content: space-between;
          margin: 0.2rem;
          transition: transform 0.5s ease;
        }

        .btn:hover {
          background-color: #0069d3;
          color: #fff;
          text-decoration: none;
          overflow: hidden;
          transform: scale(1.1);
        }
      }
    }
  }

  .switchUI {
    color: #007fff;
    position: absolute;
    bottom: 0;
    background: white;
    padding: 10px 16px;
    width: 100%;
    z-index: 1;

    .switchText {
      cursor: pointer;
    }
  }
}

@media all and (min-width: 900px) and (max-width: 1199px) {
  .projectMainDiv {
    height: 87vh;
    // margin-top: 2.5rem;
    overflow-y: scroll;
  }

  .titleSec {
    align-items: center;
  }

  .projectTitlesec {
    margin-top: 0rem !important;
    // margin-bottom: 1rem;
    padding: 0 1rem;
    margin-left: -1.5rem !important;

    .projectDetails {
      // align-items: center;
      padding-left: 1.5rem !important;
      color: #007fff;

      // padding-bottom: 1rem;
      .project_name {
        font-size: 1rem;
      }

      .project_description {
        font-size: 0.75rem;
      }
    }

    .moduleDetails {
      display: flex;
      flex-direction: column;
      row-gap: 9px;
      margin-top: 0.2rem;

      // padding: 0 1rem;
      .project_module {
        line-height: 1.2;
        font-size: 1rem;
      }

      .iconLink:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .carouselDiv {
      width: 65vw;
      padding-left: 1rem;

      // padding-bottom: 1.5rem;
      .projectImagesCoursel {
        row-gap: 2rem;

        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 220px;
          max-width: 220px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }

      .projectImages {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 1rem;

        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 220px;
          max-width: 220px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .projectTitlesec:last-child() {
    margin-bottom: 1rem !important;
  }

  .buttonSec {
    margin-top: -1rem !important;
    padding-bottom: 1rem !important;

    .innerSection {
      padding-bottom: 0rem;

      .btnLink {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        padding: 0 0.3rem;

        .btn {
          padding: 0.28rem 1rem;
          background-color: #007fff;
          width: 10rem;
          font-weight: 400;
          justify-content: space-between;
          margin: 0.2rem;
          transition: transform 0.5s ease;
        }

        .btn:hover {
          background-color: #0069d3;
          color: #fff;
          text-decoration: none;
          overflow: hidden;
          transform: scale(1.1);
        }
      }
    }
  }

  .switchUI {
    color: #007fff;
    position: fixed;
    bottom: 0;
    background: white;
    padding: 10px 16px;
    width: 100%;
    z-index: 1;

    .switchText {
      cursor: pointer;
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .projectMainDiv {
    height: 100vh;
    // background-image: url(../../Assets/images/background.png);
    // background-image: url(../../Assets/images/background2.png);
    background-position: 74.5% 75%;

    .landingPage {
      height: 95vh;
      width: 100vw;
    }
  }

  .titleSec {
    align-items: center;
  }

  .projectTitlesec {
    margin-top: 0rem !important;
    margin-bottom: 1rem;
    row-gap: 1rem;
    padding: 0 1rem;
    margin-left: -1.5rem !important;

    .projectDetails {
      padding-left: 1rem !important;
      color: #007fff;

      .innerSection {
        width: 99%;
        .btnLink {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;

          .buttonBox {
            .btn {
              padding: 0.4rem 1rem;
              background-color: #007fff;
              font-weight: 400;
              // font-size: 1.3rem;
              width: 71%;
              border-radius: 3rem;
              transition: transform 0.5s ease;
            }

            .btn:hover {
              background-color: #0069d3;
              color: #fff;
              text-decoration: none;
              overflow: hidden;
              transform: scale(1.01);
            }
          }

          .buttonBox:hover {
            transform: scale(1.06);
            transition: transform 0.5s ease;
          }
        }
      }
    }





    .prodctSec {
      padding-left: 1rem !important;
    }

    .moduleDetails {
      display: flex;
      flex-direction: column;
      row-gap: 9px;
      margin-top: 0.2rem;

      // padding: 0 1rem;
      .project_module {
        line-height: 1.2;
        font-size: 1rem;
      }

      .iconLink:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .carouselDiv {
      width: 86vw;
      padding-left: 1rem;

      // padding-bottom: 1.5rem;
      .projectImagesCoursel {
        // display: flex;
        // flex-wrap: wrap;
        row-gap: 2rem;

        //  height: 125px;
        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 200px;
          max-width: 200px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }

      .projectImages {
        // display: flex;
        // flex-wrap: wrap;
        // row-gap: 1rem;
        //  height: 125px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 1rem;

        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 200px;
          max-width: 200px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .buttonSec {
    margin-top: -1rem !important;
    padding-bottom: 1rem;

    .innerSection {
      .btnLink {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        padding: 0 0.3rem;

        .btn {
          padding: 0.28rem 1rem;
          background-color: #007fff;
          width: 10rem;
          font-weight: 400;
          justify-content: space-between;
          margin: 0.2rem;
          transition: transform 0.5s ease;
        }

        .btn:hover {
          background-color: #0069d3;
          color: #fff;
          text-decoration: none;
          overflow: hidden;
          transform: scale(1.1);
        }
      }
    }
  }

  .switchUI {
    color: #007fff;
    position: fixed;
    bottom: 0;
    background: white;
    padding: 10px 16px;
    width: 100%;
    z-index: 1;

    .switchText {
      cursor: pointer;
    }
  }
}

@media all and (max-width: 600px) {
  .projectMainDiv {
    height: 100vh;
    // background-image: url(../../Assets/images/background.png);
    // background-image: url(../../Assets/images/background2.png);
    background-position: 74.5% 75%;

    .landingPage {
      height: 87vh;
      width: 100vw;
    }
  }

  .titleSec {
    align-items: center;
  }

  .projectTitlesec {
    margin-top: 0rem !important;
    margin-bottom: 1rem;
    row-gap: 1rem;
    padding: 0 1rem;
    margin-left: -1.5rem !important;

    .projectDetails {
      padding-left: 1rem !important;
      color: #007fff;

      .innerSection {
        width: 99%;
        .btnLink {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;

          .buttonBox {
            .btn {
              padding: 0.4rem 1rem;
              background-color: #007fff;
              font-weight: 400;
              // font-size: 1.3rem;
              width: 71%;
              border-radius: 3rem;
              transition: transform 0.5s ease;
            }

            .btn:hover {
              background-color: #0069d3;
              color: #fff;
              text-decoration: none;
              overflow: hidden;
              transform: scale(1.01);
            }
          }

          .buttonBox:hover {
            transform: scale(1.06);
            transition: transform 0.5s ease;
          }
        }
      }
    }

    .project_name {
      width: 90%;
    }



    .prodctSec {
      padding-left: 1rem !important;
    }

    .moduleDetails {
      display: flex;
      flex-direction: column;
      row-gap: 9px;
      margin-top: 0.2rem;

      // padding: 0 1rem;
      .project_module {
        line-height: 1.2;
        font-size: 1rem;
      }

      .iconLink:hover {
        transform: scale(1.1);
        transition: transform 0.5s ease;
      }
    }

    .carouselDiv {
      width: 86vw;
      padding-left: 1rem;

      // padding-bottom: 1.5rem;
      .projectImagesCoursel {
        // display: flex;
        // flex-wrap: wrap;
        row-gap: 2rem;

        //  height: 125px;
        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 200px;
          max-width: 200px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }

      .projectImages {
        // display: flex;
        // flex-wrap: wrap;
        // row-gap: 1rem;
        //  height: 125px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 1rem;

        .projImage {
          margin: 0px 0.9rem;
          // margin-bottom: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 0 0.5rem;
          cursor: pointer;
          overflow: hidden;
          transition: transform 0.5s ease;
          min-width: 200px;
          max-width: 200px;
          min-height: 150px;

          .productTitle {
            text-align: center;
            width: 100%;
            // margin: 0 auto;
            // margin-bottom: 5px;
            font-size: 1rem;
          }
        }

        .projImage:hover {
          // overflow: hidden;
          transform: scale(1.05);
        }

        .proimg {
          // background-color: white;
          width: 100%;
          min-width: 100%;
          margin: 0 auto;
          height: 150px;
          min-height: 125px;
          object-fit: contain;
        }

        .progresbar {
          display: flex;
          align-items: center;
          padding: 0.2rem 0rem;

          .progText {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .buttonSec {
    margin-top: -1rem !important;
    padding-bottom: 1rem;

    .innerSection {
      .btnLink {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        padding: 0 0.3rem;

        .btn {
          padding: 0.28rem 1rem;
          background-color: #007fff;
          width: 10rem;
          font-weight: 400;
          justify-content: space-between;
          margin: 0.2rem;
          transition: transform 0.5s ease;
        }

        .btn:hover {
          background-color: #0069d3;
          color: #fff;
          text-decoration: none;
          overflow: hidden;
          transform: scale(1.1);
        }
      }
    }
  }

  .switchUI {
    color: #007fff;
    position: fixed;
    bottom: 0;
    background: white;
    padding: 10px 16px;
    width: 100%;
    z-index: 1;

    .switchText {
      cursor: pointer;
    }
  }
}

.progressbar {
  border-radius: 0 !important;
}