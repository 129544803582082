.SingupData {
  font-size: 1.1rem;
  padding: 0.2rem 0;
  padding-bottom: 0.5rem;
}
.UserNotify {
  // display: grid;
  // grid-template-columns: 3.5fr 1fr;
  // display: flex;
  // flex-direction: row;
  column-gap: 10px;
  margin-left: 0.5rem;
  color: #007fff;
  font-weight: 500;
}
.userNotifydes {
  display: flex;
  // flex-direction: row;
  // column-gap: 5px;
  // color: #007fff;
  font-weight: 500;
}
.usernotiIcon {
  cursor: pointer;
  font-size: medium;
}
.expiredData {
  background: white;
  padding: 2% 4%;
  width: 23rem;
  margin: 0.2rem 0 0 1rem;
  border-radius: 5px;
  z-index: 2;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  .topArrrow {
    color: white;
    float: right;
    margin-top: -0.4rem;
    margin-right: 1.2rem;
    position: relative;
  }
  .clearIcon {
    cursor: pointer;
    float: right;
    margin-right: -3rem;
  }
}
.saveBtn:hover {
  color: green;
}
.expiredTitle {
  border-radius: 3px;
  text-align: center;
  font-size: 0.75rem;
  color: #007fff;
  line-height: 225% !important;
}
.datepicker {
  margin-bottom: 7%;
  width: 95%;
  margin-left: 2%;
  border: none;
  border-bottom: 1px solid;
  background: none;
  padding-bottom: 10px;
}
.NotificationInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.spanNotification {
  font-size: 0.7rem;
}
.Notification_Container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.7rem;
  padding: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23);
}
.Notification_Container_active {
  display: grid;
  grid-template-columns: 4fr 0.9fr;
  column-gap: 0.5rem;
  cursor: pointer;
  margin: 0 1rem;
  align-items: center;
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
  border-left: 3px solid blue;
  background-color: aliceblue;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23);
}
.Notification_Container_active:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.19), 1px 0px 0px rgba(0, 0, 0, 0.23);
  // text-decoration: underline;
}
