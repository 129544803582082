// Clock.scss (within the same Clock.tsx file)

.clock {
  display: flex; // Use flexbox for layout
  flex-direction: row; // Stack elements vertically
  justify-content: center;
  align-items: center; // Center content horizontally
  font-family: monospace; // Use a monospace font for better alignment
  font-size: 2rem; // Adjust font size as desired
  color: #6b0606;
}

.date,
.time {
  margin: 0.5rem 0.5rem; // Add some space between date and time
}