.intro {
  margin: 0;
  padding: 0;
}
.switcher {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  position: relative;
  margin-bottom: -2rem;
}
.chartDiv {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;
  // padding: 1rem;
}
.chartLong {
  display: flex;
  align-items: center;
}
.chartLongone{
  display: block;
  text-align: center;
}
.chartLeftDiv {
  // padding: 1rem;
}
.chartRightDiv {
  margin-top: 2rem;
}
