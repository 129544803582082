.container {
  .inputField {
    font-size: 1.1rem !important;
  }
}

.btn:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.signupform {
  height: 70vh;

  // margin-top: 3rem;
  .formSectionsignup {
    position: relative;
    padding: 0 2rem;
  }

  .signupBtn {
    // font-size: 1rem;
  }

  .signupBtn:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
}

@media all and (min-width: 1200px) and (max-width:1535px) {
  .signupform {
    height: 80vh;

    // margin-top: 3rem;
    .formSectionsignup {
      // max-height: 51vh !important;
      padding: 0 2rem;
    }
  }
}


@media all and (min-width: 900px) and (max-width:1199px) {
  .signupform {
    height: 80vh;
    margin-top: 3rem;

    .formSectionsignup {
      max-height: 51vh !important;
      padding: 0 2rem;
    }
  }
}

@media all and (min-width: 601px) and (max-width: 899px) {
  .signupform {
    height: fit-content !important;
    margin-bottom: 5rem;

    .formSectionsignup {
      max-height: fit-content !important;
      padding: 0 2rem;
    }
  }

  .phoneNumber {
    margin-top: 0.5rem;
  }
}


@media all and (max-width: 600px) {
  .signupform {
    height: fit-content !important;
    margin-top: 3rem;

    .formSectionsignup {
      max-height: fit-content !important;
      padding: 0 2rem;
    }
  }
}