.content {
    max-height: calc(100vh - 4rem);
    min-height: calc(100vh - 4rem);
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    video {
        max-width: calc(100vw - 4rem) !important;
        max-height: calc(100vh - 13.5rem); //16rem with seeker
        width: auto;
    }
}

.actions {
    position: fixed !important;
    top: 2rem;
    right: 3rem;
}

.hidden {
    display: none;
}

.button {
    height: 3rem;
    width: max-content;
    margin-top: 1rem !important;
}

.seeker {
    font-size: 1rem;
    padding-top: 1rem;
}

.treeViewData {
    padding: 0.1rem;    
    .treeViewText {
        line-height: 1.2 !important;
        text-transform: capitalize;
        font-size: 1rem;
    }
}